import React, {children} from 'react'

export default function CardBox ({children}){

    return(
        
    <>
     
      <div className="w-full mx-auto md:py-10 bg-[#26150F]  rounded-xl shadow-xl ">
        {children}
     </div>
    </>    
    )
}