import React from 'react'
import { IoCalendarOutline } from "react-icons/io5";
function cardreturn ({img, alt, title,tarifa, fecha, children,reserva}) {
    
    return (
       
            <>
           

           <div className="xl:w-[90%] sm:w-[65%] xs:w-[65%]  p-5 md:p-0 mx-auto md:flex  md:flex-row xs:flex-col lg:gap-10 xs:gap-2 justify-evenly lg:items-stretch md:items-center mt-5 md:mt-0">
  <div className=" md:w-[28%] md:h-[28%]  xs:w-full ">
    <img className="rounded-xl object-contain  p-10  md:p-0 md:w-auto xs:rounded-xl mx-auto text-center mb-0 md:mb-0 hover:" src={img} alt={alt}/>
  </div>
  <div className="lg:w-[50%] sm:w-full xs:w-full  dark:text-gray-400 md:p-4 xs:p-0 rounded-md">
    <h2 className="text-4xl font-bebas  text-yellow-400 md:text-left text-center">{title}</h2>
   <div className='justify-center flex md:justify-start'> <IoCalendarOutline className='h-8 w-10 text-white' /><h2 className="text-3xl  font-bebas  text-white dark:text-white md:text-left text-center grid"> {fecha}</h2></div>
   <h2 className="text-2xl font-bebas  text-gray-100 md:text-left text-center ">{tarifa}</h2>
    <p className="mt-5 text-white mb-2 text-sm md:text-sm mt-2 md:text-left text-center">{children}</p>
    
    <div className='flex justify-center  md:justify-start'>
   
    </div>
  </div>
</div>

            </>
        )
    
}

export default cardreturn