import React from 'react'
import Button from './Button'
export default function HeroContainer ({title, img,text1, text2,} ){
  
    return(
      <>
<div className="bg-no-repeat bg-cover relative h-[65vh]  md:h-[120vh] w-full max-w-[100vw]  ">

  
    <div
      className="absolute inset-0 m-auto   my-4 md:my-0 w-full h-full flex justify-center flex-col items-center"
    >
     <h1 className="md:pt-10 lg:text-6xl text-3xl  xs:text-3xl sm:text-3xl text-center font-peter text-yellow-300 mb-5 tracking-widest animate-fade-left animate-duration-[1500ms]  animate-once animate-ease-in">{text1}</h1>
     <h1 className="text-center text-5xl  sm:text-6xl md:text-9xl -rotate-6   font-peter text-yellow-300  mb-20 tracking-widest animate-fade-down animate-duration-[1500ms] animate-once animate-ease-in z-1">{text2}</h1>
     <h1 className="text-center text-5xl  sm:text-6xl md:text-9xl -rotate-6  blur-md font-peter text-amber-500  mb-5 tracking-widest animate-fade-down animate-duration-[1500ms] absolute  animate-fade object-cover object-center animate-fade-up animate-once animate-ease-in  animate-once animate-ease-in ">{text2}</h1>
      <Button href="/reserva"> <h1 className="text-3xl font-bebas">reservar</h1> </Button>  
      <div class="scroll-down">
      <span></span>
      <span></span>
      <span></span>
      </div>
    </div>
    <img
      className="absolute cp-v h-full w-full animate-fade object-cover object-center animate-duration-1000 animate-ease-in-out -z-10"
      src={img}
      alt=""
      
    />
    <img
      className="absolute  animate-fade object-cover object-center animate-fade-up animate-once animate-ease-in blur-3xl -z-40"
      src={img}
      alt=""
    />
   
  </div>

  </>
    )
}