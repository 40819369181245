import React, {useState} from 'react'
import Logo from '../images/logo.png'
import Button from './Button'
import {Link} from 'react-router-dom'

function ResponsiveMenu() {
    {
      /* state to manage the current width of the window */
    }
    const [windowWidth, setWindowWidth] = React.useState(window.innerWidth);
  
    {
      /* state to manage if Menu is toggle or not  */
    }
    const [toggleMenu, setToggleMenu] = React.useState(false);

    {
      /* useEffect that adjust the current size of the window and set the toggleMenu that need to be set */
    }
    React.useEffect(() => {
      windowWidth >= 768 ? setToggleMenu(true) : setToggleMenu(false);
      window.addEventListener("resize", () => setWindowWidth(window.innerWidth));
  
      return () => {
        window.removeEventListener("resize", () =>
          setWindowWidth(window.innerWidth)
        );
      
      
      };
    }, [windowWidth]);

    
    return (
      <div className="z-10 relative">
        {/*  begining of the nav component */}
        <nav className="fixed top-0  w-full flex items-center bg-amber-500  shadow-xl justify-between  px-12  p-3   text-white md:py-0 ">
          {/* logo */}
          <div href="" className="h-12 md:h-full mr-20">
            <a href="/" className="">
              <img
                src={Logo}
                alt=""
                className="w-full h-full object-contain"
              />
            </a>
          </div>
  
          {/* navLink */}
          <div
            className={` text-center z-10 shadow-xl cp-v absolute w-screen top-0 left-0 flex flex-col items-center bg-amber-500 gap-4 py-12 md:py-3   duration-500  
            ${
              toggleMenu ? "translate-y-0" : "-translate-y-[100vh]"
            } md:flex-1 md:flex-row md:justify-end md:relative md:gap-10 `  }
          >
            
              <Link to="/" className="hover:-translate-y-1 duration-300 text-2xl " onClick={() => {windowWidth >= 768 ? setToggleMenu(true) : setToggleMenu(false);}}>Home</Link> 
              <Link to="/sobre" className="hover:-translate-y-1 duration-300 text-2xl" onClick={() => {windowWidth >= 768 ? setToggleMenu(true) : setToggleMenu(false);}}>Sobre Nosotros</Link>
              <Link to="/gallery" className="hover:-translate-y-1 duration-300 text-2xl" onClick={() => {windowWidth >= 768 ? setToggleMenu(true) : setToggleMenu(false);}}>Galeria</Link>
              <Link to="/itinerario" className="hover:-translate-y-1 duration-300 text-2xl" onClick={() => {windowWidth >= 768 ? setToggleMenu(true) : setToggleMenu(false);}}>Itinerario</Link>
              <Link to="/contacto" className="hover:-translate-y-1 duration-300 text-2xl" onClick={() => {windowWidth >= 768 ? setToggleMenu(true) : setToggleMenu(false);}}>Contacto</Link>
              <Link to="/reserva" className="text-2xl border-2 btn p-4 relative border-0   uppercase text-amber-900 shadow bg-transparent hover:delay-[.5s] transition-all duration-500 hover:text-white before:absolute before:left-0 before:bottom-0 before:h-[2px] before:w-0 before:transition-all before:duration-500 before:bg-amber-600 before:hover:w-full after:absolute after:left-0 after:bottom-0 after:h-0 after:w-full after:transition-all after:duration-500 after:bg-amber-300 after:hover:h-full after:text-amber-700 after:-z-10 after:hover:delay-[0.4s]" onClick={() => {windowWidth >= 768 ? setToggleMenu(true) : setToggleMenu(false);}}>Reservar</Link>
            
          </div>
        </nav>
  
        {/* hamburger button */}
        <button
          className={`fixed top-5 right-12 md:hidden text-white`}
          onClick={() => {
            setToggleMenu(!toggleMenu);
          }}
        >
          {toggleMenu ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
              />
            </svg>
          )}
        </button>
  
      </div>
    );
  }
  
export default ResponsiveMenu;