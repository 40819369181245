const Photos = [
    {
      src: "/images/gallery/page/1.jpeg",
      width: 960,
      height: 1280,
      
    },
    {
      src: "/images/gallery/page/2.jpeg",
      width: 1280,
      height: 960,
      
    },
    {
      src: "/images/gallery/page/3.jpeg",
      width: 960,
      height: 1280,
      
    },
    
    {
      src: "/images/gallery/page/4.jpeg",
      width: 960,
      height: 1280,
      
    },

    {
      src: "/images/gallery/book9.jpg",
      width: 770,
      height: 446
    },
    
    {
      src: "/images/gallery/page/5.jpeg",
      width: 960,
      height: 1280,
      
    },
    
    {
      src: "/images/gallery/book8.jpg",
      width: 770,
      height: 446
    },
    {
      src: "/images/gallery/page/6.jpeg",
      width: 960,
      height: 1280,
      
    },
    
    {
      src: "/images/gallery/page/7.jpeg",
      width: 960,
      height: 1280,
      
    },
    {
      src: "/images/gallery/piscina/piscina-5.jpg",
      width: 1600,
      height: 1200
    },
    {
      src: "/images/gallery/page/8.jpeg",
      width: 960,
      height: 1280,
      
    },
  
 
    {
      src: "/images/gallery/page/9.jpeg",
      width: 960,
      height: 1280,
      
    },
    {
      src: "/images/gallery/page/10.jpeg",
      width: 960,
      height: 1280,
      
    },
    {
      src: "/images/gallery/page/11.jpeg",
      width: 960,
      height: 1280,
      
    },
    {
      src: "/images/gallery/piscina/piscina-4.jpg",
      width: 1600,
      height: 1200
    },
    {
      src: "/images/gallery/page/12.jpeg",
      width: 960,
      height: 1280,
      
    },
    {
      src: "/images/gallery/page/13.jpeg",
      width: 960,
      height: 1280,
      
    },
    {
      src: "/images/gallery/page/14.jpeg",
      width: 960,
      height: 1280,
      
    },
    {
      src: "/images/gallery/page/15.jpeg",
      width: 960,
      height: 1280,
      
    },
    {
      src: "/images/gallery/page/16.jpeg",
      width: 960,
      height: 1280,
      
    },
    {
      src: "/images/gallery/page/17.jpeg",
      width: 960,
      height: 1280,
      
    },
    {
      src: "/images/gallery/page/18.jpeg",
      width: 960,
      height: 1280,
      
    },
  
    {
      src: "/images/gallery/page/19.jpeg",
      width: 960,
      height: 1280,
      
    },
    {
      src: "/images/gallery/page/20.jpeg",
      width: 960,
      height: 1280,
      
    },
    {
      src: "/images/gallery/page/21.jpeg",
      width: 960,
      height: 1280,
      
    },
    {
      src: "/images/gallery/page/22.jpeg",
      width: 960,
      height: 1280,
      
    },
    {
      src: "/images/gallery/piscina/9.jpeg",
      width: 960,
      height: 1280
    },
    {
      src: "/images/gallery/page/23.jpeg",
      width: 960,
      height: 1280,
      
    },
    {
      src: "/images/gallery/page/24.jpeg",
      width: 960,
      height: 1280,
      
    },
    {
      src: "/images/gallery/page/25.jpeg",
      width: 960,
      height: 1280,
      
    },
    {
      src: "/images/gallery/page/26.jpeg",
      width: 960,
      height: 1280,
      
    },
    {
      src: "/images/gallery/page/27.jpeg",
      width: 960,
      height: 1280,
      
    },
    
  ];
export default Photos;