import React, {useEffect} from 'react'
import {useParams} from 'react-router-dom'
import Error from './Error'
import Succes from './Success'


const Finalizacion = (props) => {

    let {estado, idReserva} = useParams(); 
    
    window.scrollTo(0,0);
  
        return (
          <div className='pt-20 m-20' >

         { Number(estado) === 1 ? <><Succes numero={idReserva} /></> : <><Error/></>}
          
         </div>
              );

}

export default Finalizacion
