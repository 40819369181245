import React, {useState} from "react";
import PhotoAlbum from "react-photo-album";
import HeroContainer from '../components/HeroContainer';

import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import Photos from '../components/gallerys/Gallery-photos.js'

export default function Gallery(){
    const [index, setIndex] = React.useState(-1);
   

    return(
        <>
        <HeroContainer img="images/pileta-up.jpg" text1="Galeria de fotos" text2="La Margarita"/>
        <div className="md:m-10 md:pt-0 pt-20" >
            <div className="bg-gray-600/20 md:p-10 rounded-xl">
      
        <PhotoAlbum layout="masonry" 
        sizes={{
            size: "992px",
            sizes: [
              { viewport: "(max-width: 767px)", size: "calc(100vw - 32px)" },
              { viewport: "(max-width: 1279px)", size: "calc(100vw - 288px)" },
            ],
          }}
          onClick={({ index: current }) => setIndex(current)}
        photos={Photos} 
        targetRowHeight={150} 
        />
        
       
        
        <Lightbox
        index={index}
        slides={Photos}
        open={index >= 0}
        close={() => setIndex(-1)}
      />
      </div>
      </div>
        </>
       
    )
}