import React from 'react'
import HeroVideo from '../components/HeroVideo'
import CardBox from '../components/CardBox'
import CardLeft from '../components/Card'
import Cardright from '../components/Card-2'
import HeroContainer from '../components/HeroContainer'
import SliderDivisor from '../components/SwiperSlider'


export default function Home (){

    

    return(
        <>
        <div className='md:pt-5'>
        <HeroContainer img={'images/home-up.jpg'} text1="Estancia" text2="La Margarita"/>
        </div>
        <div className="md:m-5 md:pt-20 ">
          
        <CardBox>
            
        <CardLeft title="¡EXPERIENCIAS ÚNICAS EN UN LUGAR ÚNICO!" img="images/gallery/book3.jpg">"¡Bienvenidos a 'La Margarita'! Situada en un entorno rural idílico, nuestra estancia ofrece una escapada perfecta para aquellos que buscan desconectar de la rutina y sumergirse en la tranquilidad del campo.

                Con una capacidad para 500 personas, nuestras amplias instalaciones están diseñadas para satisfacer todas tus necesidades y deseos. 
        </CardLeft>
        
                <Cardright  title="Cada visita es una experiencia diferente." img="images/home/3.jpeg">En 'La Margarita', cada visita es una experiencia diferente. Ven y descubre la belleza y la serenidad de nuestro entorno natural, donde nuestras tradiciones se combinan con el confort moderno para ofrecerte una escapada inolvidable.

                ¡Te esperamos con los brazos abiertos para vivir momentos inolvidables en el corazón del campo!"
                </Cardright>

         <CardLeft title="Delicias Rurales: Sabores Auténticos en La Margarita" img="images/home/7.jpeg"> En 'La Margarita', la experiencia culinaria es tan excepcional como el entorno que la rodea. Desde el desayuno hasta la merienda, cada comida es una celebración de sabores auténticos y platos tradicionales que deleitarán tus sentidos.

             Nuestros chefs expertos utilizan ingredientes frescos y locales para crear platos que reflejan la riqueza de la gastronomía argentina, garantizando así una experiencia culinaria inolvidable para todos nuestros huéspedes. Ven y únete a nosotros en la mesa, donde cada comida es una celebración de la vida y la amistad.
        </CardLeft>
        
                     </CardBox>
                     </div>


                     <div className="mt-5 md:mt-0 md:p-0 ">
                     <SliderDivisor/>
                      
                     </div>
                     
                    <div className="md:m-5">
                     <CardBox>
            
        <CardLeft title="¡Actividades para Todos! Deportes y Entretenimiento" img="images/gallery/book4.jpg">En 'La Margarita', el deporte y el entretenimiento se entrelazan en un escenario vibrante y lleno de energía. Nuestro extenso terreno ofrece una variedad de opciones para que todos, desde los más competitivos hasta los que buscan relajarse, encuentren su actividad ideal. 
        </CardLeft>
        
                <Cardright  title="Sumérgete en la Diversión: Disfruta de Nuestra Refrescante Piscina" img="images/home/2.jpeg">¡En 'La Margarita', la diversión nunca se detiene, especialmente cuando te sumerges en nuestras refrescantes aguas! Nuestra piscina es el lugar perfecto para refrescarte y relajarte bajo el cálido sol del campo. Con un diseño cuidadosamente planificado y una atmósfera relajante, nuestra piscina ofrece un oasis de tranquilidad y diversión.
                </Cardright>

         <CardLeft title="¡Entretenimiento en Vivo en 'La Margarita': Donde la Diversión Nunca Termina!" img="images/home/9.jpeg"> 
				Nuestro escenario se convierte en el centro de atención mientras los artistas locales y regionales deleitan a nuestros huéspedes con su talento y pasión. Desde música folclórica tradicional hasta éxitos contemporáneos, hay algo para todos los gustos y edades en nuestros espectáculos. 
				Ya sea que estés buscando una noche relajada bajo las estrellas o una fiesta animada con amigos y familiares, nuestros shows en vivo en 'La Margarita' son el complemento perfecto para tu escapada rural. ¡Prepárate para emocionarte, reír y aplaudir mientras disfrutas de la magia del entretenimiento en vivo en nuestro encantador refugio rural!"
        </CardLeft>
        
                     </CardBox>
                    
             </div>
            
             <div>
                
             <div className='md:m-2'>
        <h2 className='md:font-peter font-montserrat text-xl p-10 md:text-5xl text-white text-center pt-10 md:pb-10 '> Sumérgete en la serenidad y deja que la naturaleza te abrace.</h2>
        <h1 className='md:font-peter text-4xl md:text-8xl  text-amber-500 text-center md:-rotate-3 pb-10'>Estancia La Margarita</h1>
        </div>
             </div>
        </>
    )
};