import React from 'react'
import {Link, useParams} from 'react-router-dom'
import toast from 'react-hot-toast'
import { VscError } from "react-icons/vsc";
const Error = (props) => {
  let {idReserva} = useParams(); 
  return (
    <div className="pt-20">
        <div className=' bg-white md:m-20 p-10 md:rounded-xl'>
        <div>
          <div className='flex justify-center '>
        <VscError className='w-48 h-48 text-red-600 ' />
        </div>
        
        <h1 className='font-bebas text-3xl text-center'>Su n&uacute;mero de Pre reserva es: <strong>{idReserva}</strong></h1>
               <p className='text-center font-bebas'>Ha ocurrido un error durante el pago de la Reserva. Por favor comuniquese a nuestros telefonos.</p>
               <p className='text-center font-bebas text-lg'>para asegurar sus lugares en la Estancia.</p>
               <p className='text-center font-bebas text-lg'>Muchas Gracias.</p>
           
              
        </div>
        <div className=' flex justify-center'>
        <Link to="/" className="float-center  mb-3 inline-flex h-12 items-center justify-center gap-2 whitespace-nowrap rounded bg-emerald-500 px-6 text-md font-medium tracking-wide text-white transition duration-300 hover:bg-emerald-600 focus:bg-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-emerald-300 disabled:bg-emerald-300 disabled:shadow-none">Finalizar</Link>
        </div>
        </div>
   
     <br />
     
   </div>
  )
}

export default Error