import React, {useLayoutEffect} from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import Navbar from '../components/Navbar'
import Footer from '../components/Footer';
import { Toaster } from 'react-hot-toast';
import '../css/global.css'
import { ButtonWhatsApp } from '../components/ButtonWhatsApp';
const Layout = () => {
    const location = useLocation();
  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [location.pathname]);
  
return (
    <>
    <Navbar/>
    <div className="grid grid-cols-1 grid-rows-1 gap-0 m-0"><Outlet/> </div>
    <ButtonWhatsApp/>
    <Footer/>
    <Toaster />
    
    </>
)
}
export default Layout;