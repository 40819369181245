import React from 'react'

function cardreturn ({img, alt, title, children}) {
    
    return (
       
            <>
           

<div className="xl:w-[90%] sm:w-[85%] xs:w-[90%] mx-auto md:flex  md:flex-row xs:flex-col lg:gap-4 xs:gap-2 justify-center lg:items-stretch md:items-center mt-4">
  <div className=" lg:w-[50%] lg:h-[50%]  xs:w-full">
    <img className="rounded-xl  object-contain mx-auto  md:m-0 hover:shadow-xl hover:contrast-125 " src={img} alt={alt} />
    
  </div>
  <div className="lg:w-[50%] sm:w-full xs:w-full  dark:text-gray-400 md:p-4 xs:p-0 rounded-md p-5 md:p-0">
    <h2 className="text-2xl font-semibold text-white md:text-left text-center">{title}</h2>
    <p className="text-sm text-center md:text-left mt-2 text-gray-100">{children}</p>
  </div>
</div>

            </>
        )
    
}

export default cardreturn