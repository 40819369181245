import React from "react";
import { URL_BASE } from "./rutasfijas";

export {deBaseAFechaLocal, capitalizeFirstLetter, convertirFecha,sumarFecha,deJSAFechaAmericana, agregarMesaEnStockEnDemanada}

function agregarMesaEnStockEnDemanada(idEvento, numeroExternoMesa, sillas, accion) {
  const sessionId = localStorage.getItem('sessionId');
  const objetoConsulta = {
    token: sessionId,
    idEvento,
    idReserva: 0,
    accion,
    numeroExternoMesa,
    modalidadUbicacion: 0,
    numeroSilla: 0,
    cantidadSillas: sillas.length
  };

  fetch(`${getURLBasePath()}/servicioMesa.php`, {
    method: 'POST',
    body: JSON.stringify({
      peticion: 22,
      Pobjeto: objetoConsulta 
    }), 
    headers: new Headers()
  }).then(res => res.json())
    .catch(error => console.error('Error:', error))
    .then(resultado => {
      console.log('Stock', resultado)
    });
};

function deBaseAFechaLocal(fecha_en_iso) {
  if (fecha_en_iso === "0001-01-01T00:00:00")
  return "";

  var regexp_fecha_iso = /(\d{1,4}-\d{1,2}-\d{1,2})T(\d{1,2}:\d{1,2}:\d{1,2})/;
  if (regexp_fecha_iso.test(fecha_en_iso)) {
    fecha_en_iso = (regexp_fecha_iso.exec(fecha_en_iso)[1]).replace(/-/g, "/");
  }

  var fecha = new Date(fecha_en_iso);
  if (fecha.getUTCDate().toString() === "NaN")
  return "";

  return fecha.getUTCDate().toString() + "/" + (fecha.getUTCMonth() + 1).toString() + "/" + fecha.getUTCFullYear();
}

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

function convertirFecha(fecha) {
  //Formato: Lunes 18 de Marzo de 2018
  const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
  let fechaEnJS = new Date(fecha);
  fechaEnJS.setDate(fechaEnJS.getDate() + 1);
  return fechaEnJS.toLocaleDateString("es-ES", options)
}

function deJSAFechaAmericana(date) {
  var d = new Date(date),
  month = '' + (d.getMonth() + 1),
  day = '' + d.getDate(),
  year = d.getFullYear();
  if (month.length < 2)
  month = '0' + month;
  if (day.length < 2)
  day = '0' + day;
  return [year, month, day].join('-');
}


function sumarFecha(fechaDesde, cantidadDias) {
  let milisegundos = Number(35 * 24 * 60 * 60 * 1000);

  let fecha = new Date();
  let fechaEnJS = new Date(fechaDesde);
  fechaEnJS.setDate(fechaEnJS.getDate() + 1);

  //const day = fechaEnJS.getDate();
  // el mes es devuelto entre 0 y 11
  //const month = fechaEnJS.getMonth() + 1;
  //const year = fechaEnJS.getFullYear();

  //Obtenemos los milisegundos desde media noche del 1/1/1970
  const tiempo = fechaEnJS.getTime();
  //Calculamos los milisegundos sobre la fecha que hay que sumar o restar...
  milisegundos = Number(cantidadDias * 24 * 60 * 60 * 1000);
  //Modificamos la fecha actual
  fecha.setTime(tiempo + milisegundos);

  return deBaseAFechaLocal(deJSAFechaAmericana(fecha));
}

export function addDays(date, days) {
  var result = new Date(date);
  result.setDate(result.getDate() + days);
  result.setHours(0,0,0,0);//las horas las pongo en 0 para poder comparar la parte de fecha
  return result;
}

export const getURLBasePath = () => {
  if (window.location.href.includes('192.168.0.240')) {
    //return 'http://192.168.0.240/margaritan/php/servicios'; //ANTES:
   return 'https://estanciamargarita.com.ar/backend/php/servicios';}
   else {
   
  return URL_BASE
};
}

export const armarPoliticas = (evento = {}) => {
  if (evento.politicaPrecioMovArray && evento.politicaPrecioMovArray.length > 0 ) {
    return evento.politicaPrecioMovArray.map((pol, i) => {
      return <span key={i} className="price trajan-pro">{pol.descripcion} <br /></span>;
    })
  }

  if (evento.tarifasRackObj) {
    return <span className="price trajan-pro">Tarifa $ {evento.tarifasRackObj.tarifaAdultoRack} <br /></span>; 
  }
 return '';
}

export const armarPromociones = (evento = {}) => {
  if (evento.promocionMovArray) {
    return evento.promocionMovArray.map((promo, i) => {
      return <span key={i} className="price trajan-pro">{promo.descripcion} <br /></span>;
    })
  }
  return '';
}
