import HeroContainer from '../components/HeroContainer'
export default function Sobre (){
    return(
        <>
        <HeroContainer img='images/margarita-up.jpg' text1="sobre" text2="Nosotros"/>
        <section id="sobrenosotros" className="max-w-screen-xl mx-auto py-20 md:px-8 lg:px-10  ">
  <div className="rounded-xl p-10 shadow-xl bg-[#26150F]/20 ">

    
    <h2 className="text-4xl xl:text-5xl capitalize  text-center text-amber-500 font-arquitecta font-bold">Sobre Nosotros</h2>
    <hr className="mx-auto w-12 h-1 outline-0 border-0 bg-amber-400 block mt-4 mb-6"/>
    <p className="text-center text-2xl text-gray-200">"La Margarita: Donde los Sueños se Hacen Realidad en el Corazón del Campo"</p>
  
    <div className="flex flex-col gap-6 mt-16">
      <div className="flex flex-col md:flex-row gap-6">
        <div className="w-full lg:w-1/4 rounded-3xl overflow-hidden">
          <img src="/images/gallery/book3.jpg" alt="" className="w-full h-full object-cover aspect-video lg:aspect-square"/>
        </div>
        <div className="w-full lg:w-9/12 bg-[#F2C879] rounded-3xl flex flex-col justify-center p-8 lg:p-14">
          <h3 className="text-2xl capitalize text-amber-800 font-semibold">Estancia La Margarita:</h3>
          <span className="inline-block capitalize text-xl text-amber-900 font-light mt-1.5 mb-5"></span>
          <p className="text-amber-900 opacity-75 leading-normal text-lg">Ubicada en un paisaje idílico, la Estancia La Margarita es un refugio que combina la serenidad del campo con el lujo y la comodidad moderna. Desde el momento en que atraviesas sus tranquilas tranqueras, te sumerges en un mundo de belleza natural y paz.</p>
          
        </div>
        
      </div>
      <div className="flex flex-col md:flex-row-reverse gap-6">
        <div className="w-full lg:w-1/4 rounded-3xl overflow-hidden">
          <img src="/images/atardecer-up.jpg" alt="" className="w-full h-full object-cover aspect-video lg:aspect-square"/>
        </div>
        <div className="w-full lg:w-9/12 bg-blue-100 rounded-3xl flex flex-col justify-center p-8 lg:p-14">
          <h3 className="text-2xl capitalize text-indigo-900 font-semibold"></h3>
          <span className="inline-block capitalize text-xl text-indigo-900 font-light mt-1.5 mb-5"></span>
          <p className="text-indigo-900 opacity-75 leading-normal text-lg">Con una belleza natural que roba el aliento, La Margarita te invita a explorar sus vastos terrenos salpicados de árboles frondosos y jardines florecientes. Cada rincón está impregnado de la calidez y la autenticidad del campo argentino, creando un escenario idílico para escapar del ajetreo y el bullicio de la vida cotidiana.</p>
        </div>
      </div>
      <div className="flex flex-col md:flex-row gap-6">
        <div className="w-full lg:w-1/4 rounded-3xl overflow-hidden">
          <img src="/images/home/5.jpeg" alt="" className="w-full h-full object-cover aspect-video lg:aspect-square"/>
        </div>
        <div className="w-full lg:w-9/12 bg-[#F2C879] rounded-3xl flex flex-col justify-center p-8 lg:p-14">
          <h3 className="text-2xl capitalize text-indigo-900 font-semibold"></h3>
          <span className="inline-block capitalize text-xl text-indigo-900 font-light mt-1.5 mb-5"></span>
          <p className="text-amber-900 opacity-75 leading-normal text-lg">Pero La Margarita es mucho más que un simple refugio; es un destino de ensueño donde los sueños se hacen realidad. Aquí, la diversión y la aventura esperan en cada esquina, desde emocionantes actividades al aire libre hasta espectáculos en vivo que cautivan el corazón.</p>
        </div>
      </div>
      <div className="flex flex-col md:flex-row-reverse gap-6">
        <div className="w-full lg:w-1/4 rounded-3xl overflow-hidden">
          <img src="/images/gallery/book6.jpg" alt="" className="w-full h-full object-cover aspect-video lg:aspect-square"/>
        </div>
        <div className="w-full lg:w-9/12 bg-green-100 rounded-3xl flex flex-col justify-center p-8 lg:p-14">
          <h3 className="text-2xl capitalize text-indigo-900 font-semibold"></h3>
          <span className="inline-block capitalize text-xl text-indigo-900 font-light mt-1.5 mb-5"></span>
          <p className="text-indigo-900 opacity-75 leading-normal text-lg">Ya sea que estés buscando una escapada romántica, unas vacaciones familiares o un retiro tranquilo, La Margarita te espera con los brazos abiertos para brindarte una experiencia inolvidable en el corazón del campo argentino. ¡Ven y descubre el encanto de La Margarita, donde los sueños se hacen realidad y los recuerdos duran para siempre!"</p>
        </div>
      </div>
    </div>
  </div>
  </section>
        
        </>
    )
};