import React from 'react'
import {armarPoliticas, capitalizeFirstLetter, convertirFecha} from '../../tools/utils';
import { Link } from 'react-router-dom'
import CardReserva from '../../CardReserva'
import CardBox from '../../CardBox';
const EventoFila = props => {

  const { miEvento, imagen, onEventoClick } = props;

  // const showPrecioForDay = (fecha) => {
  //     //si es domingo
  //     const dia = new Date(fecha).getDay();
  //     if (dia === 6) {
  //       return '$ 3450';
  //     }
  //     return '$2900';
  // }

  // const showDiscountForDay = (fecha) => {
  //     //si es domingo
  //     const dia = new Date(fecha).getDay();
  //     if (dia === 6) {
  //       return '25%';
  //     }
  //     return '20%';
  // }

  // const armarPoliticas = (politicasDePrecio) => {
  //   return politicasDePrecio.map(pol => {
  //     return <span key={pol.id} className="price trajan-pro">{pol.descripcion} <br /></span>;
  //   })
  // }

  return (
    
    <div className='p-0 md:pl-20 md:pr-20 md:pt-10 md:pb-5'>
    <CardBox>
    <CardReserva img={imagen} title={miEvento.productoM.denominacion} fecha={convertirFecha(miEvento.fecha)} tarifa={armarPoliticas(miEvento)}>
   
    {miEvento.productoM.observaciones}
        
       <br/>
          <Link onClick={() => onEventoClick(miEvento)}  role="button" to={`/formulario/${miEvento.idEvento}`} >
            <span className="mt-5 inline-flex h-12 items-center justify-center gap-2 whitespace-nowrap rounded bg-emerald-500 px-6 text-md font-medium tracking-wide text-white transition duration-300 hover:bg-emerald-600 focus:bg-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-emerald-300 disabled:bg-emerald-300 disabled:shadow-none">Reservar</span>
          </Link>
        
      
    </CardReserva>  
    </CardBox>
    </div>
  );

}
/*

{miEvento.productoM.id !== 2 && <span className="price trajan-pro">Precio: Antes <strike>{showPrecioForDay(miEvento.fecha)}</strike> promoción descuento <span style={{color:'red'}}>{showDiscountForDay(miEvento.fecha)}</span> ahora queda ARS $ {miEvento.productoM.tarifasM.tarifaAdulto} por persona.</span> } 
{miEvento.productoM.id === 2 && <span className="price trajan-pro">ARS $ {miEvento.productoM.tarifasM.tarifaAdulto} por persona.</span> }
*/
export default EventoFila
