import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'
import {capitalizeFirstLetter, convertirFecha, getURLBasePath} from '../../components/tools/utils';
import LoaderFormal from '../../components/tools/LoaderFormal'
import toast from 'react-hot-toast'
const Pagar = (props) => {
  const { totalReserva, reserva, evento, totalPax} = props;
  const [importeAPagar, setImporteAPagar] = useState(totalReserva);
  const [loader, setLoader] = useState(false);
  const [sillasCotizadas, setSillasCotizadas] = useState(reserva.sillas);
  const [habilitarBoton, setHabilitarBoton] = useState('ok');
  const [tarjetaElegida, setTarjetaElegida] = useState('0');

  window.scrollTo(0,0);

  useEffect(() => {
    
    const objetoConsulta = {
      cualAfecta: 'Ori',
      llamadoDesde: 'W',
      filtrarContenido: 1,
      eventoObj: evento,
      paxTotales: totalPax,
      seleccionArray: reserva.sillas,
      clienteObj: {idEntidad: 23, idTipoEntidadG: 0 }
    };
    fetch(`${getURLBasePath()}/servicioPromocionPrecios.php`, {
      method: 'POST', 
      body: JSON.stringify({peticion: 13, pObjeto: objetoConsulta}), 
      headers: new Headers()
    }).then(res => res.json())
    .catch(error => console.error('Error:', error))
    .then(resultado => {
      if (resultado) {
        if (resultado.estado === 'exito') {
            //reserva.sillasCotizadas = resultado.data;
            setSillasCotizadas(resultado.data);
        } else {
          toast.error("Ha ocurrido un error:" +resultado.mensaje)
        }
      } else {
        alert('Ha ocurrido un error indefinido');
      }
    });
  },[]);

  const insertarReserva = (e) => {
   

      e.preventDefault();
     

      let medioDePago = e.target.name;
      //FC: validar el pago de al menos el 30%
      // if (medioDePago === 'mp' && parseFloat(this.state.importeAPagar) < (this.props.totalReserva * 0.3)) {
      //     alert('Debe abonar al menos el 30% del total de la reserva');
      //     return;
      // }
      const sessionId = localStorage.getItem('sessionId');
      let reservaCopy = Object.assign({}, reserva);

      reservaCopy.tarjetaElegida = tarjetaElegida;
      reservaCopy.idEvento = evento.idEvento;
      reservaCopy.evento.fecha = evento.fecha;
      reservaCopy.paxTotales = totalPax;
      reservaCopy.compartir = 0;
      reservaCopy.sillasCotizadas = sillasCotizadas;
      reservaCopy.sessionId = sessionId;
      //reserva.totalReserva = this.props.totalReserva;
      reservaCopy.totalReserva = totalReserva;
      reservaCopy.tarifaBaseAdulto = evento.productoM.tarifasM.tarifaAdulto;
      reservaCopy.tarifaBaseMenor = evento.productoM.tarifasM.tarifaMenor;

      reservaCopy.observacion += verificarSiTienePlan();
      /*
      if (reserva.paxAdultos == 2 && reserva.paxMenores == 1) {
        reservaCopy.observacion += '. (20% de descuento sobre la tarifa del menor)';
        reservaCopy.tarifaBaseMenor = evento.productoM.tarifasM.tarifaAdulto * 0.8;
      } else if (reserva.paxAdultos == 2 && reserva.paxMenores == 2) {
        reservaCopy.observacion += '. (25% de descuento sobre la tarifa de los menores)';
        reservaCopy.tarifaBaseMenor = evento.productoM.tarifasM.tarifaMenor * 0.75;
      }
      */
      //reserva.importeAPagar = this.state.importeAPagar;

      if (
          !reservaCopy.idEvento || 
          !reservaCopy.evento.fecha || 
          Number(reservaCopy.totalReserva) === 0 || 
          reservaCopy.paxTotales === 0
          ) {
          
          toast.error("Datos incompletos. Por favor vuelva a cargar el formulario.")
          return;
      }

      if (medioDePago === 'mp' && reservaCopy.tarjetaElegida === '0') {
        toast.error("Debe seleccionar una tarjeta.")
          return;
      }

      //this.setState({habilitarBoton: ''});
      setLoader(true);
      const url = `${getURLBasePath()}/serviciosReserva.php`;
      const data = {peticion: 30, reserva: reservaCopy, medioDePago: medioDePago};
      
       
      fetch(url, {

        method: 'POST', 
        body: JSON.stringify(data), 
        headers: new Headers()
      }).then(res => res.json())
      .catch(error => console.error('Error:', error))
      .then(function (resultado) {
        {console.log(resultado)}
        if (resultado) {
          if (resultado.estado === 'exito') {

            if (medioDePago === 'mp') {
                //alert('Bien: ' + JSON.stringify(resultado));
                //FC: modo prueba
                //window.location.href = resultado.data.response.sandbox_init_point;
                //FC: modo produccion
                window.location.href = resultado.data.data;
                //window.location.href = resultado.data.response.init_point;
                //document.location.assign(resultado.data.response.init_point);
              } else {
                props.history.push('/finalizacion/' + resultado.data +'/1');
              }

          } else {
            toast.error(resultado.mensaje)
          }

        } else {
          toast.error("Ha ocurrido un error indefinido:")
        }

        //_this.setState({habilitarBoton: 'ok'});
        setLoader(false);
      });
    }

  const handleImporteAPagarChange = (e) => {
    setImporteAPagar(e.target.value);
  }

  const handleTarjetaChange = (e) => {
    setTarjetaElegida(e.target.value);
  }

  const findPromotionsApply = (sillas = []) => {
    const groupByArray = (xs, key) => { 
      return xs.reduce((rv, x) => { 
      let v = key instanceof Function ? key(x) : x[key]; 
      let el = rv.find((r) => r && r.key === v); 
      if (el) { 
        el.values.push(x); 
      } else { 
        rv.push({ key: v, values: [x] }); 
      } 
      return rv; 
    }, []);
   } 
    let texto = "";
    const sillasAgrupadasPorPromocion = groupByArray(sillas, 'idPromocionMov');
    const sillasConPromocion = sillasAgrupadasPorPromocion.map(obj => obj.values.filter(s => s.idPromocionMov !== 0));
    sillasConPromocion.forEach(s => s.textoPromocionAplicada ? texto += s.textoPromocionAplicada + ' ' : '');
    return texto;
  };


  const verificarSiTienePlan = () => {
    return sillasCotizadas[0] && sillasCotizadas[0].textoPromocionAplicada;
    /*
    if (Number(reserva.paxAdultos) === 2 && Number(reserva.paxMenores) === 1) {
      return <p className="parrafoEnMedioDePago">Bonificación del 20% sobre la tarifa del menor. 
            </p>;
    } else if (Number(reserva.paxAdultos) === 2 && Number(reserva.paxMenores) === 2) {
        return <p className="parrafoEnMedioDePago resaltar">Bonificación del 25% sobre la tarifa de los menores
              </p>;
      }
    */
  }

  const calcularTotalReserva = descuento => {
    // return ((Number(evento.productoM.tarifasM.tarifaAdulto) * reserva.paxAdultos) + (Number(evento.productoM.tarifasM.tarifaMenor) * descuento * reserva.paxMenores)).toFixed(2);
  }


  const calcularTotalAPagar = () => {
    let total = 0;
    sillasCotizadas.forEach(silla => {
      total += silla.tarifaBaseAdultoAplicada;
    });

    if (evento.idPlantillaSalon === 99) {
      total = evento.tarifasRackObj.tarifaAdultoRack * totalPax ;
    }
    return Math.round(total).toFixed(2);
    /*
    let total = totalReserva;
    if (Number(reserva.paxAdultos) === 2 && Number(reserva.paxMenores) === 1) {
      total = calcularTotalReserva(0.8);//total * 0.9;
    } else if (Number(reserva.paxAdultos) === 2 && Number(reserva.paxMenores) === 2) {
      total =  calcularTotalReserva(0.75); //total - evento.productoM.tarifasM.tarifaMenor;
    }

    return total;
    */
  }

  const textoPromocionAplicada = findPromotionsApply(sillasCotizadas);

  return (
        <div className="pt-20">
          <div className='bg-white md:m-20 p-10 md:rounded-xl'>
         <LoaderFormal loaded={loader} mensaje="Reservando..." />
         
          <h4 className='font-bebas text-3xl text-center '>Pagar y Reservar</h4>
          <hr />
          <br/>
          
          
          <div className="">
            {/*<p>Atención: En caso de que no abone su reserva por adelantado nos contactaremos para poder gestionar el cobro dentro de las 24 hs. Las reservas deben abonarse antes del evento para poder ingresar a la Estancia.</p> */}
            <div className=' grid    rounded md:rounded-xl' >
              
          

<div className='flex flex-wrap -mx-3 mb-6  gap-0 md:gap-5  '>
  
  <div className='w-full md:w-3/6 px-3 mb-6 md:mb-0 rounded-xl p-10 text-center md:text-left'>
    <hr/>
    <p className='font-bebas text-2xl '>Resumen de sus datos:</p>
    <hr className='pb-3'/>
<p className='font-bebas text-4xl '>D&iacute;a del Evento: <strong>{capitalizeFirstLetter(convertirFecha(evento.fecha))}</strong></p>
<p className='font-bebas text-4xl'>Total de Personas: <strong>{totalPax}</strong> </p> 
              
              <p className='font-bebas text-4xl'>Total de Reserva: <strong>${totalReserva}.</strong>  </p> 
              <br/>
              <hr/>
             
              {/* <p><strong>Mesas Elegidas:</strong> {reserva.mesas.map(m => `${m} `)} </p> */}
             
              <p className='text-2xl font-bebas'>Datos del Contacto:</p>
              <hr className='pb-3 '/>
                 <p className='font-bebas '>- Titular: {reserva.titular}</p>
                 <p className='font-bebas'>- Email: {reserva.email}</p>
                 <p className='font-bebas'>- Telefono: {reserva.telefono}</p>
              
            </div>
            <div className='w-full md:w-2/5 px-3 p-10  md:mb-0  rounded-xl md:text-right text-center'>
            <div className="">
              <div className="">
                {/*
                <div className="col-xl-5 col-lg-5 col-md-5 col-sm-12 col-12">
                  <h4 className="tituloCajaMedioPago">Efectivo</h4>
                  <p>Abonando en efectivo el total de la reserva en nuestra Estancia <br /></p>
                  {verificarSiTienePlan()}
                  <p style={{'margin': '0', 'height': '30px'}}>
                      <strong className="resaltar">$ {calcularTotalAPagar()}  </strong>
                  </p>

                  <button className="btn_pagar btn-default" name="eft" onClick={insertarReserva} disabled={!habilitarBoton}>Pagar en La Margarita</button>
                </div>

                <div className="col-xl-5 col-lg-5 col-md-5 col-sm-12 col-12">
                    <h4 className="tituloCajaMedioPago">Tarjeta de Cr&eacute;dito</h4>
                    <p>Abonando con Mercado Pago obtené un 10% de descuento.</p>
                    {this.verificarSiTienePlan()}
                    <p style={{'margin': '0', 'height': '30px'}}>
                      <span>$ {this.calcularTotalAPagar()} - 10% = </span><strong className="resaltar">$ {this.calcularTotalAPagar() * 0.9}</strong>
                    </p>
                    {/* $ <input style={{'width': '100px'}} type="number" name="importeAPagar" onChange={this.handleImporteAPagarChange}
                      //        placeholder="Importe a Pagar" value={this.state.importeAPagar} />}

                    <button className="btn_pagar btn-default" name="mp" onClick={this.insertarReserva} disabled={!this.state.habilitarBoton}>Pagar con Mercado Pago</button>
                  </div>*/}

                  <div className="p-2 ">
                      <h4 className="font-bebas  text-2xl">Tarjeta de Cr&eacute;dito</h4>
                      {/* <p>Abonando en un pago con tarjeta obtené un 10% de descuento.</p> */}
                      {textoPromocionAplicada !== '' && <strong className="resaltar">{textoPromocionAplicada}</strong>}
                      <p style={{'margin': '0', 'height': '30px'}}>
                        {/* <span>$ {calcularTotalAPagar()} - 10% = </span><strong className="resaltar">$ {calcularTotalAPagar() * 0.9}</strong> */}
                        <p className=' text-5xl font-bebas'>Total: $ {totalReserva}</p>
                      </p>
                      <div className='w-full md:w-3/3 px-3 mb-3 md:mb-5 '>
                        <label className="block uppercase tracking-wide text-gray-500 text-xs font-bold mb-2 pt-5">Disponible solo en un pago</label>
                        <select className="block w-full bg-gray-100 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" name="tarjeta" id="tarjeta" value={tarjetaElegida} onChange={handleTarjetaChange}>
                          <option value='0'>Seleccionar Tarjeta</option>
                          <option value="V">Visa</option>
                          <option value="M">Mastercard</option>
                          <option value="A">American Express</option>
                          <option value="D">Dinners</option>
                        </select>
                        
                      </div>
                      {/* $ <input style={{'width': '100px'}} type="number" name="importeAPagar" onChange={handleImporteAPagarChange}
                                placeholder="Importe a Pagar" value={importeAPagar} />*/}
                  <Link className="float-center  mb-3 inline-flex h-12 items-center justify-center gap-2 whitespace-nowrap rounded bg-emerald-500 px-6 text-md font-medium tracking-wide text-white transition duration-300 hover:bg-emerald-600 focus:bg-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-emerald-300 disabled:bg-emerald-300 disabled:shadow-none" name="mp" onClick={insertarReserva} disabled={!habilitarBoton}>Pagar con Tarjeta de Cr&eacute;dito</Link>
                     
                    </div>

              </div>
            </div>
            </div>
            </div>
            
            </div>
            
            <Link className="btn_evento_fila btn-default" to='/reserva'>Volver</Link>
          </div>
        </div>
        </div>
      );
  }

  export default Pagar
