const Home1 = [
    {
        id: 1,
        src: "images/home/1.jpeg",
        alt: "metegol",
        key: 1,
    },
    {
        id: 2,
        src: "images/home/2.jpeg",
        alt: "piscina",
        key: 2,
    },
    {
        id: 3,
        src: "images/home/3.jpeg",
        alt: "parque",
        key: 3,
    },
    {
        id: 4,
        src: "images/home/4.jpeg",
        alt: "carro",
        key: 4,
    },
    {
        id: 5,
        src: "images/home/5.jpeg",
        alt: "hermoso lugar",
        key: 5,
    },
    {
        id: 6,
        src: "images/home/6.jpeg",
        alt: "carro con arbol",
        key: 6,
    },
    {
        id: 7,
        src: "images/home/7.jpeg",
        alt: "mesas y sillas",
        key: 7,
    },
    {
        id: 8,
        src: "images/home/8.jpeg",
        alt: "mesas y sillas exterior",
        key: 8,
    },
    {
        id: 9,
        src: "images/home/9.jpeg",
        alt: "mesas y sillas exterior",
        key: 9,
    },
    {
        id: 10,
        src: "images/home/10.jpeg",
        alt: "mesas y sillas exterior",
        key: 10,
    },

]
export default Home1;