import React from 'react'
import { FaWhatsapp } from "react-icons/fa";
import { Link } from 'react-router-dom'
export const ButtonWhatsApp = () => {
    
  return (
    <>
  <Link to="https://wa.me/5491161490517?text=Hola!+quiero+reservar+mi+estadia+en+Estancia+La+Margarita+" target="_blank" className="whatsapp-btn">
  <FaWhatsapp className='h-20 w-8 text-white hover:text-green-900'/></Link>
    </>
  )
}
