import Route from './components/Route'

function App() {
  return (
    <div className="App">
      <Route/>
    </div>
  );
}

export default App;
